
.grandCarde{
    width: 100%;
  
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 3.5px 10.5px;
    border-radius: 10px;
    border: solid 2px #268d36;
    background-color: #fff;
}
.Rectangle-292 {
    width: 180px;
    height: 50px;
    float: right;
    margin: 0 0 10.5px 12.5px;
    padding: 0 0 0.5px 35.5px;
    background-color: #268d36;
    text-align: center;
    border-radius: 0px 0px 0 5px;
  }
  .REU {
    width: 21.5px;
    height: 10px;
    margin: 6px 35px 6px 0;
    font-family: Ubuntu;
    font-size:20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.38px;
    text-align: center;
    color: #000;
  }
  .REU .text-style-1 {
    font-size: 30px;
    letter-spacing: 0.5px;
  }
  .Rectangle-294 {
    
    height: 50px;
    /* margin: 0 0 0 35px; */
    float: right;
    margin: 0 0px 5px 0px;
    padding: 10PX 40px 0.5px 35.5px;
    background-color: #fff;
    border : 1px solid hsl(129, 58%, 35%);
    border-radius: 0px 10px 0px 0px;
    text-align: center;
  }

  .A000-1 {
    
    font-family: Ubuntu;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
  
    text-align: center;
    color: #000;
  }
  .input {
    width: 300px;
    height: 25px;
    margin: 0px 70px 6px 3px;
    background-color: rgba(38, 141, 54, 0.46);
    border:  rgba(38, 141, 54, 0.46);
    border-bottom:  rgba(38, 141, 54, 0.46);
    box-shadow:  rgba(38, 141, 54, 0.46);
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.35px;
    text-align: left;
    color: #000;
  }
  .inputNom{
    width: 600px;
    height: 25px;
    /* margin: 0px 70px 6px 3px; */
    background-color: rgba(38, 141, 54, 0.46);
    border:  rgba(38, 141, 54, 0.46);
    border-bottom:  rgba(38, 141, 54, 0.46);
    box-shadow:  rgba(38, 141, 54, 0.46);
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.35px;
    text-align: left;
    color: #000;
  }
  .montant {
   float: right;
  }
  .recude{
   margin: 0px 0px 0px 30px;
  }
  .Classe-6em-A6em-FR {
    margin: 0px 0px 0px 50px;
  
  }
  .ligne {
   height: 2px;
   margin: 0px 0px 0px 30px;
    background-color: rgba(38, 141, 54, 0.46);
  }
  .lasomme{
    margin: 0px 0px 0px 30px;
  }
  .inputSomme {
     width: 560px;
    height: 25px;
    /* margin: 0px 70px 6px 3px; */
    background-color: rgba(38, 141, 54, 0.46);
    border:  rgba(38, 141, 54, 0.46);
    border-bottom:  rgba(38, 141, 54, 0.46);
    font-family: Ubuntu;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.35px;
  text-align: left;
  color: #000;
    box-shadow:  rgba(38, 141, 54, 0.46);
  }
  @media only screen and (max-width :1200px) {
    .inputNom{
        width: 420px;
        height: 25px;
        /* margin: 0px 70px 6px 3px; */
        background-color: rgba(38, 141, 54, 0.46);
        border:  rgba(38, 141, 54, 0.46);
        border-bottom:  rgba(38, 141, 54, 0.46);
        box-shadow:  rgba(38, 141, 54, 0.46);
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.35px;
        text-align: left;
        color: #000;
      }
      .inputSomme {
        width: 400px;
       height: 25px;
       /* margin: 0px 70px 6px 3px; */
       background-color: rgba(38, 141, 54, 0.46);
       border:  rgba(38, 141, 54, 0.46);
       border-bottom:  rgba(38, 141, 54, 0.46);
       font-family: Ubuntu;
     font-size: 20px;
     font-weight: bold;
     font-stretch: normal;
     font-style: normal;
     line-height: normal;
     letter-spacing: 0.35px;
     text-align: left;
     color: #000;
       box-shadow:  rgba(38, 141, 54, 0.46);
     }

  }
  @media only screen and (max-width :780px) {
    .inputNom{
        width: 320px;
        height: 25px;
        /* margin: 0px 70px 6px 3px; */
        background-color: rgba(38, 141, 54, 0.46);
        border:  rgba(38, 141, 54, 0.46);
        border-bottom:  rgba(38, 141, 54, 0.46);
        box-shadow:  rgba(38, 141, 54, 0.46);
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.35px;
        text-align: left;
        color: #000;
      }
      .inputSomme {
        width: 320px;
       height: 25px;
       /* margin: 0px 70px 6px 3px; */
       background-color: rgba(38, 141, 54, 0.46);
       border:  rgba(38, 141, 54, 0.46);
       border-bottom:  rgba(38, 141, 54, 0.46);
       font-family: Ubuntu;
     font-size: 20px;
     font-weight: bold;
     font-stretch: normal;
     font-style: normal;
     line-height: normal;
     letter-spacing: 0.35px;
     text-align: left;
     color: #000;
       box-shadow:  rgba(38, 141, 54, 0.46);
     }
    }
     @media only screen and (max-width :569px) {
        .inputNom{
            width: 220px;
            height: 25px;
            /* margin: 0px 70px 6px 3px; */
            background-color: rgba(38, 141, 54, 0.46);
            border:  rgba(38, 141, 54, 0.46);
            border-bottom:  rgba(38, 141, 54, 0.46);
            box-shadow:  rgba(38, 141, 54, 0.46);
            font-size: 20px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.35px;
            text-align: left;
            color: #000;
          }
          .inputSomme {
            width: 220px;
           height: 25px;
           /* margin: 0px 70px 6px 3px; */
           background-color: rgba(38, 141, 54, 0.46);
           border:  rgba(38, 141, 54, 0.46);
           border-bottom:  rgba(38, 141, 54, 0.46);
           font-family: Ubuntu;
         font-size: 20px;
         font-weight: bold;
         font-stretch: normal;
         font-style: normal;
         line-height: normal;
         letter-spacing: 0.35px;
         text-align: left;
         color: #000;
           box-shadow:  rgba(38, 141, 54, 0.46);
         }
         .ligne {
            height: 2px;
            margin: 0px 0px 0px 10px;
             background-color: rgba(38, 141, 54, 0.46);
           }
           .lasomme{
             margin: 0px 0px 0px 10px;
           }
           .recude{
            margin: 0px 0px 0px 10px;
           }
           .Classe-6em-A6em-FR {
             margin: 0px 0px 0px 50px;
           
           }
           .ligne {
            height: 2px;
            margin: 0px 0px 0px 10px;
             background-color: rgba(38, 141, 54, 0.46);
           }
           .lasomme{
             margin: 0px 0px 0px 10px;
           }
           .input {
            width: 250px;
            height: 25px;
            margin: 0px 70px 6px 10px;
            background-color: rgba(38, 141, 54, 0.46);
            border:  rgba(38, 141, 54, 0.46);
            border-bottom:  rgba(38, 141, 54, 0.46);
            box-shadow:  rgba(38, 141, 54, 0.46);
            font-size: 20px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.35px;
            text-align: left;
            color: #000;
          }
         
          .montant {
           /* float: right; */
           margin: 0px 0px 0px 10px;
          }
          
.Rectangle-292 {
    width: 170px;
    height: 50px;
    float: right;
    margin: 0 0 10.5px 12.5px;
    padding: 0 0 0.5px 35.5px;
    background-color: #268d36;
    text-align: center;
    border-radius: 0px 0px 0 5px;
  }
  .Rectangle-294 {
    width: 150px;
    height: 50px;
    /* margin: 0 0 0 35px; */
    float: right;
    /* margin: 0 0px 5px 0px; */
    /* padding: 10PX 40px 0.5px 35.5px; */
    background-color: #fff;
    border : 1px solid hsl(129, 58%, 35%);
    border-radius: 0px 10px 0px 0px;
    text-align: center;
  }
  .A000-1 {
    
    font-family: Ubuntu;
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
  
    
    color: #000;
  }
  .Rectangle-180{

    float: right;
    width: 120px;
    margin-top: 200px;
    margin-right: 100px;

  }
  }