.inputSelect{
    position: relative;
    
}

.icon-select{
    position: absolute;
    top: 42px;
    right: -20px;
    display: inline-bloc;
    width: 25px;
    height: 25px;
    color: #268d36;
}