.title-form{
    color: #268d36;
    position: relative;
    top:-20px;
    right: -10px;
    width: 280px;
    padding: 0 10px;
   background-color: #fff;
   font-weight: 600;
   margin-left: 100px;
}
.styleCard{
    border: 1px solid #c0bbbb;

    /* rgb(222, 222, 222); */
}
.btn-action{
    font-size: 1.2em;
    text-transform:capitalize;
    font-weight:700 ;
    color: #fff;
    background: #268d36;
    display: inline-block;
    cursor: pointer;
    letter-spacing: 1px;
    outline: none;
    border: none;
    border-radius: 5px;
    transition: 0.5s;
    padding: 10px 20px ;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.btn1-action{
    font-size: 1em;
    text-transform:capitalize;
    font-weight:700 ;
    color: #fff;
    background: #268d36;
    display: inline-block;
    cursor: pointer;
    letter-spacing: 1px;
    outline: none;
    border: none;
    border-radius: 15px;
    transition: 0.5s;
    padding: 10px;
    padding-bottom: 10px;
    margin-right: 60px;
    margin-left: 60px;
    width: 150px;  
    height: 35px;  
}
.btn-classe{
    font-size: 0.85em;
    text-transform:capitalize;
    font-weight:700 ;
    color: #fff;
    background: #268d36;
    display: inline-block;
    cursor: pointer;
    letter-spacing: 1px;
    outline: none;
    border: none;
    border-radius: 15px;
    transition: 0.4s;
    padding: 8px 15px;
}
.btn-classe:hover{
    color: rgb(255, 254, 254);
    background-color: #0e6d1c;
}
.btn-classe.btn2{
    margin: 0 0 0 -18px;
}
.btnAfichageClasse{
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}