.title-form-niveau{
    color: #268d36;
    position: relative;
    top:-27px;
    left: -40px;
    width:max-content ;
    padding: 0 2px;
   background-color: #fff;
   font-weight: 600;
   margin-left: 100px;
}
