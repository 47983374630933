/* ####### Navebar ####### */
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@100;200;300;400&display=swap');
*{
    font-family: 'IBM Plex Sans', sans-serif;
}
.navebar{
    border-bottom: 1px solid #eee;
    background-color: #fff;
    margin: 0;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px; 
    justify-content: space-between;
    position: fixed;
    height: 80px;
    width: 100%;
    top: 0;
    z-index: 2; 
    display: flex;
    padding: 0 25px 0 0;
}
.recherche-content{
    width: 600px;
 }
.recherche{
    margin-top: 20px;
    padding: 0;
}
.content-navebar-title{
    display: flex;
    /* justify-content: space-between; */
}
.icon-humburger{
    color: #0168fa;
    margin-top: 26px;
    margin-right: 10px;
    font-size: 18px;
    display: none;
    cursor: pointer;

}
.navebar-title{
    color: #031a61;
    margin-top: 20px;
    font-weight: 600;
}
.navebar-title span{
    color: #0168fa;
}

.section-profil{
    display: flex;
    justify-content: end; 
    /* margin-left: -40px;  */
}
.profil{
    margin-top: 10px
}
.content-langue{
    position: relative;
}
.profilNav{
    height: 55px ;
    width: 55px;
    background-color: #bbb;
    border-radius: 50%;
    margin-top: 0;

}

  .profilUser{
    height: 55px ;
    width: 55px;
    background-color: #bbb;
    border-radius: 50%;

/* width: 55px !important;
background-color: #bbb;
border-radius: 50%;
margin-left: -8px !important; */
   
  }

  .content-langue{
    margin-top: 20px;
  }
  .dropdown-toggle.avatar::after  {
    display: none;
    }
    .profil-nom{
        margin-top: 7px;
    }
    .profil-nom .nomProfil{
        margin-bottom: 0;
        margin-left: 15px;
        margin-top: 10px;
    }
    .profil-nom .profil-profil{
        margin-left: 15px;
        color: rgba(0, 0, 0, .50);
    }
    .content-sidebar-main{
        display: flex;
    }
  

.sidebar{
    background-color: #fff;
    border-right: 1px solid #eee ;
    /* box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, 
                rgba(27, 31, 35, 0.15) 0px 0px 0px 1px; */
    position: fixed;
    top: 80px;
    left:0;
    /* width: 250px; */
    width: 260px;
    overflow-x: hidden;
    transition:  0.5s  ;
   
}
.scroller{
    height: 90vh;
    scrollbar-color:  rgb(182, 176, 176) #eee;
    overflow-y: auto;
    scrollbar-width: thin;

  }
  .scroller:hover{
    scrollbar-color: rgb(36, 36, 36) #eee ;
    overflow-y: scroll;
    scrollbar-width: thin;
  }
 
  .scroller::-webkit-scrollbar{
    width: 6px;

  } 
  .scroller::-webkit-scrollbar-thumb{
	background-color: rgb(182, 176, 176);
}  
.scroller::-webkit-scrollbar-thumb:hever {
    background-color: #000;
}  
  .scroller::-webkit-scrollbar-track{
    background-color:  #eee;;
  } 
  .scroller::-webkit-scrollbar-track:hever{
    background-color: #eee;
  } 

/* les accordions */
.accueil{

    margin: 15px 0 5px 10px;
}
.accueil .accueil-link{
    text-decoration: none;
    margin-left: 10px;
    color: #001737;
    font-size: 18px;

   
}
.accordion-button{
    color: #001737;
    font-size: 18px;
    /* font-weight: 600; */
   
}
.icon{

}
.icon1 {
    width: 20px;
    height: 20px;
    margin-top: -5px;
}

.icon2{
    width: 40px;
}
.icon3{
    width: 50px;
    height: 20px;
    margin-top: 7px;
    /* margin-left:20px ; */
}
.body-accordion{
    margin-left: 15px;
    border-left: 2px solid #eee;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px;
}
.body-accordion p{
    margin-left: 25px;
    margin-bottom: 4px;
}
.body-accordion .link{
    text-decoration: none;
    color: #001737;
    font-size: 16px;
    opacity: 0.6;
}

.sousListe{
    opacity: 0.6;
}
 /* ###### Main #######  */
 .main{
    position: relative;
    /* left: 250px; */
    left: 260px;
    /* width: calc(100% - 250px); */
    width: calc(100% - 260px);
    margin-top: 80px;
    height: 100vh;
    padding: 20px;
    transition: 0.5s;
 }
 .sidebar-modifie{
    left:0;
    /* width: 250px; */
    width: 260px;
    z-index: 10;
  
 }

 .sidebar-open{
    left:0;

 }
 .sidebar-closed{
    left: -260px;
 }
 .main-open{
    left: 260px;

 }
 .main-closed{
    left: 0;
    width: 100%;
 }
@media only screen and (max-width :1125px) {
    .recherche-content{
       width: 400px;
        }
    }
@media only screen and (max-width :935px) {
    .recherche-content{
        display: none;
    }
        
    }
@media only screen and (max-width :527px) {
    .navebar-title{
    font-size: 18px;
    font-weight: 600;
    }
    .content-langue{
    display: none;
    }
            
}

 @media only screen and (max-width :730px) {
     .icon-humburger{
        display: block;
    }
     
}    