

.content-form {
    height: 100vh;
    width: 100%;
    background-image: linear-gradient(rgba(56, 194, 120, 0.53), rgba(56, 194, 120, 0.53)), url(../../img/background.jpeg);
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact .titre-text span {
    color: #268d36;
    font-size: 2em;
}

.contactform {
    padding: 75px 50px;
    border-radius: 10px;
    background: #fff;
    box-shadow: 5px 15px 50px rgba(0, 0, 0, .8);
    width: 500px;
 
}
.contactformmodif{
    padding: 75px 50px;
    border-radius: 10px;
    background: #fff;
    box-shadow: 5px 15px 50px rgba(0, 0, 0, .8);
    width: 40%;
}
.contactform .inputboite {
    position: relative;
    width: 100%;
    margin-bottom: 20px;
}

.contactform h3 {
    color: #268d36;
    text-align: center;
    font-weight: 700;
    margin-bottom: 30px;
    margin-top: -10px;
}

.contactform p {
    color: #111;
    font-size: 18px;
    text-align: center;
    font-weight: 600;
}

.contactform p a {
    text-decoration: none;
    color: #111;
}

.contactform .inputboite input {
    width: 95% !important;
    border: 1px solid #268d36;
    border-radius: 5px;
    padding: 10px;
    color: #111;
    outline: none;
    font-weight: 300;
}

.btn-connexion {
    font-size: 1.2em;
    text-transform: capitalize;
    font-weight: 700;
    color: #fff;
    background: #268d36;
    display: inline-block;
    cursor: pointer;
    letter-spacing: 1px;
    outline: none;
    border: none;
    border-radius: 5px;
    transition: 0.5s;
    padding: 15px 0;
    width: 100% !important;
    align-items: center;
    justify-content: center;
}

.btn-connexion:hover {
    color: #fff;
    background: #369945;
}