.eleveconteiner {
    width: 90%;
    margin: 0 0 10px;
   
}

.eleveform .inputboite {
    position: relative;
    width: 100%;
    margin-bottom: 20px;
}

.eleveform h3 {
    color: #268d36;
    text-align: center;
    font-weight: 700;
    margin-bottom: 30px;
    margin-top: -10px;
}
.bar{
    background-color: #000;
    border: 1px solid #000;
    width: 90%;
}

.eleveform p a {
    text-decoration: none;
    color: #111;
}

.eleveform .inputboite input {
    width: 100px;
    border: 1px solid #268d36;
    border-radius: 5px;
    padding: 10px;
    color: #111;
    outline: none;
    font-weight: 300;
    margin: 0 10px 1px -5px;
}

.eleveform .form-group select {
    width: 100%;
    border: 1px solid #268d36;
    border-radius: 5px;
    padding: 5px;
    color: #111;
    outline: none;
    font-weight: 300;
}

.table-bordered {
    width: 100%;
}

.text {
    margin: 0 0 13px 37px;
    /* padding: 3px 10px 3.5px 7.5px; */
    font-size: 17px;
    font-family: Ubuntu;
    font-size: 18.5px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.33px;
    text-align: center;
    color: #000;
    margin-top: 20px;
    margin-bottom: 3px;
    text-align: center;
}

.class {
    width: 90% !important;
    height: 3px;
    /* // margin: 0 0 10px; */
    background-color: #000000;
    margin: 0 0 13px 37px;
    padding: 3px 10px 3.5px 7.5px;
}

.form-check {
    color: #020202;
    font-size: 17px;
}

.form-check-input input[type="radio"] {
    color: #33de04;
}

.formulaire fieldset {
    border: 4px solid #268d36;
    border-radius: 20px;
    position: relative;
    padding: 0 important;
    margin: 0 0 13px 37px;
}

.title-form {
    color: #268d36;
    position: relative;
    top: -20px;
    right: -90px;
    width: 200px;
    padding: 0 1px;
    background-color: rgb(255, 255, 255);
    font-weight: 600;
    margin-left: 100px;
}

.conteneur-btn {
    display: flex;
    justify-content: center;
    position: relative;
    top: 30px;
}

.btn1-connexion {
    font-size: 1.2em;
    text-transform: capitalize;
    font-weight: 700;
    color: #fff;
    display: inline-block;
    cursor: pointer;
    letter-spacing: 1px;
    outline: none;
    border: none;
    border-radius: 5px;
    transition: 0.5s;
    padding: 10px 10px;
    width: 200px;
}

.Rectangle-69 {
    display: flex;
    justify-content: center;
}

.inscription {
    font-size: 1.2em;
    text-transform: capitalize;
    font-weight: 700;
    color: #fff;
    display: inline-block;
    cursor: pointer;
    letter-spacing: 1px;
    outline: none;
    border: none;
    border-radius: 10px;
    transition: 0.5s;
    padding: 10px 10px;
    width: 350px;
    background-color: #268d36;
    position: relative;
    left: 100px;
    top: 25px;
    z-index: 12;

}

.reinscription {
    font-size: 1.2em;
    text-transform: capitalize;
    font-weight: 700;
    color: #fff;
    display: inline-block;
    cursor: pointer;
    letter-spacing: 1px;
    outline: none;
    border: none;
    border-radius: 10px;
    border: 1px solid #268d36;
    transition: 0.5s;
    padding: 10px 10px;
    margin-right: 60px;
    margin-left: 60px;
    width: 350px;
    background-color: #fff;
    position: relative;
    right: 10px;
    top: 25px;
    z-index: 10;

}
.reinscription a{
    text-decoration: none;
}
.inscription1 a{
    text-decoration: none;
}

.text {
    display: inline-block;
    padding-top: 30px;
}

.btn1-connexion.enregistrer {
    background: #268d36;
}

.btn1-connexion.annuler {
    background: #e5ca3a;
}
.content-link-eleve{
    margin-bottom: 20px;
    

}
.content-link-eleve a{
    text-decoration: none;
    margin-left: 20px;

}
.fieldset-inscrire{
    padding: 20px 30px 20px 20px  ;
}

@media only screen and (max-width :768px) {
    .inputboite input {
        width: 90%;
        margin-bottom: 10px;
    }
    .inputboite select {
        width: 90%;
    }
    .icon-chevron {
        right: 50px;
        cursor: auto;
    }
    .upload {
        width: 100px;
        position: relative;
    }
    .title-upload {
        text-align: center;
        margin-bottom: 70px;
        margin-top: -5px;
    }
}

@media only screen and (max-width :505px) {
    fieldset {
        width: 400px;
    }
    .title-form {
        right: -10px;
        left: -40px;
    }
}

@media only screen and (max-width :436px) {
    fieldset {
        border: 1px solid #268d36;
        border-radius: 20px;
        position: relative;
        width: 350px;
    }
    .title-form {
        left: -60px;
        font-size: 22px;
        padding: 0 5px;
        width: 220px;
    }
    .btn1-connexion {
        padding: 10px 20px;
        margin-right: 30px;
        margin-left: 30px;
        width: 200px;
    }
    .Rectangle-69 {
        width: 300px;
        height: 50.5px;
        margin: 9px 109px 0px 33px;
        padding: 0 18.5px 0 0.5px;
        border-radius: 5px;
        border: solid 0.5px #268d36;
        background-color: #fff;
        position: relative;
        margin-left: 500px;
        top: 20px;
    }
}