/* .title-bultin{
    text-align: center;
    width: 100%;
    margin: 0;

}
.tableNote tr{
   text-align: center; 
}
.tableNote tr th{
    font-size: 25px;
}

.header-h3{
    margin-top: 50px;

}
hr{
    background-color: #000;
    padding: 0;
    margin: 0 0 5px 0;
    height: 1px;  
}

.entete-tableau{
    background-color: rgb(242, 240, 240);
}


.inputBulletin{
    width: 25px;
    height: 25px;
}
.footer-bulletin{
    font-size: 20px;
    font-weight: 600;
    font-family: sans-serif;

}

@media print {
	@page {
		margin-top: 0;
		margin-bottom: 0;
        size: A4;
        margin-top: 2mm;
        margin-left: 10mm;
        margin-right: 10mm;
	}
	body {
		padding-top: 5px;
		padding-bottom: 50px ;
	}
}
.print {
	@page :footer {
		display: none
	}

	@page :header {
		display: none
	}
} 

#ligneTab td{
    padding: 3px 3px 3px 10px;
}

#tabApreciation th, #tabApreciation td{
    padding: 3px 3px 3px 10px;
} */



body {
    margin: 0;
    padding: 0;
    background-color: #FAFAFA;
    font: 12pt "Tahoma";
}
* {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
}

.page {
    width: 21cm;
    min-height: 29.7cm;
    padding: 0.3cm 0.5cm 0.3cm 0.5cm;
    margin: 0.5cm auto;
    /* border: 1px #D3D3D3 solid;
    border-radius: 5px; */
    background: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    page-break-after: always;
}

.subpage {
    padding: 1cm;
    border: 5px red solid;
    height: 256mm;
    outline: 2cm #FFEAEA solid;
}

@page {
    size: A4;
    margin: 0;
}
@media print {
    .page {
        margin: 0;
        border: initial;
        border-radius: initial;
        width: initial;
        min-height: initial;
        box-shadow: initial;
        background: initial;
        page-break-after: always;
    }
}