.entete {
    width: 100%;
    box-shadow: 2PX 2px 2px 0PX rgba(56, 194, 120, 0.53);
    border: solid 0.5px #c0bbbb;
    border-bottom: 1px solid;
    border-radius: 0 0 10PX 10px;
    background-color: #fff;
}

.enteteVert {
    width: 100%;
    height: 100px;
    /* box-shadow: 0 2px 2px 0 #e5ca3a; */
    border: solid 0.5px #c0bbbb;
    background-color: rgba(56, 194, 120, 0.53);
}

.image {
    width: 90px;
    height: 85px;
    margin: 5px 10px 44px 29px;
    padding: 23px 20px 18px 40px;
    border-radius: 100%;
    margin-top: -15px;
    border: solid 1px #e5ca3a;
    background-color: #d9d9d9;
}

.buton {
    height: 25px;
    flex-grow: 0;
    margin: 0.5px 8px 12.5px 0.5px;
    /* padding: 2.5px 2.5px 2px 3.5px; */
    border-radius: 7.5px;
    border: 1px solidrgba(56, 194, 120, 0.53);
    text-align: center;
    background-color: #e1efe3;
    color: #fff;
}

.link {
    color: rgb(5, 5, 5);
}

.link:hover {
    color: rgb(5, 5, 5);
}

.buton:active {
    background-color: rgba(56, 194, 120, 0.53);
}

.cardVert {
    width: 100%;
    height: 90px;
    margin: 10px 21px 12.5px 12px;
    padding: 10px 6px 7.5px 7px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.25);
    border: solid 1px rgba(56, 194, 120, 0.53);
    background-color: #fff;
}

.cardZone {
    width: 100%;
    height: 90px;
    margin: 10px 21px 12.5px 12px;
    padding: 10px 6px 7.5px 7px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.25);
    border: solid 1px #e5ca3a;
    background-color: #fff;
}

.cardBlue {
    width: 100%;
    height: 90px;
    margin: 10px 21px 12.5px 12px;
    padding: 10px 6px 7.5px 7px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.25);
    border: solid 1px #3a42e5;
    background-color: #fff;
}

.Ellipse-1 {
    height: 70.5px;
    margin: 0px 20px 2px 10PX;
    border-radius: 50% !important;
    background-color: rgba(56, 194, 120, 0.53);
}

.Ellipse-2 {
    width: 60.5px;
    height: 60.5px;
    margin: 1px 2px 2px 0;
    background-color: #e5ca3a;
    border-radius: 50%;
}

.Ellipse-3 {
    width: 60.5px;
    height: 60.5px;
    margin: 1px 2px 2px 0;
    background-color: #3a42e5;
    border-radius: 50%;
}
.classe {
    /* margin: -10px 10.5px 0 5.5px; */
    font-family: Ubuntu;
    font-size: 20.5px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    margin-top: 10px;
    margin-left: 5px;
}

.cardEnBat {
    width: 60%;
    border: 2px solid rgba(56, 194, 120, 0.53);
    /* box-shadow: 2px 2px 2px 2px rgba(56, 194, 120, 0.53); */
    background-color: #fff;
    margin-left: O;
}