
.icon-selectReportage{
    position: absolute;
    top: 22px;
    right: -10px; 
    display: inline-bloc;
    width: 25px;
    height: 25px;
    color: #268d36;
}
.icon-selectReportage1{
    position: absolute;
    top: 35%;
    right: 10%; 
    display: inline-bloc;
    width: 25px;
    height: 25px;
    color: #268d36;
}
.icon-selectReportage2{
    position: absolute;
    top: 35%;
    right: 10%; 
    display: inline-bloc;
    width: 25px;
    height: 25px;
    color: #268d36;
}
.icon-selectReportage3{
    position: absolute;
    top: 35%;
    right: 10%; 
    display: inline-bloc;
    width: 25px;
    height: 25px;
    color: #268d36;
}
.inputSelectReportage select{
    position: relative;
    all: unset;
    border: 1px solid #268d36;
    width: 80%;
    outline: none;
    padding: 10px 10px 10px 10px;
    margin: 10PX 10px 10px 30px;
    color: #111;
    border-radius: 5px;
}
.inputSelectReportage1{
    position: relative;
}

.inputSelectReportage2{
    position: relative;
}

.inputSelectReportage3{
    position: relative;
}

.inputSelectReportage1 select{
    all: unset;
    border: 1px solid #268d36;
    width: 80%;
    display: inline-block;
    outline: none;
    padding: 10px 10px 10px 10px;
    margin: 10PX 10px 10px 30px;
    color: #111;
    border-radius: 5px;
}
.inputSelectReportage2 select{
    all: unset;
    border: 1px solid #268d36;
    width: 80%;
    outline: none;
    padding: 10px 10px 10px 10px;
    margin: 10PX 10px 10px 30px;
    color: #111;
    border-radius: 5px;
}
.inputSelectReportage3 select{
    all: unset;
    border: 1px solid #268d36;
    width: 80%;
    outline: none;
    padding: 10px 10px 10px 10px;
    margin: 10PX 10px 10px 30px;
    color: #111;
    border-radius: 5px;
}
