.title-form-paiement{
    color: #268d36;
    position: relative;
    top:-27px;
    right: 10px;
    width:max-content ;
    padding: 0 2px;
   background-color: #fff;
   font-weight: 600;
   margin-left: 100px;
}
.inputboite input{
    all: unset;
      width: 100%; 
      border: 1px solid #268d36;
      border-radius: 15px;
      padding: 10px; 
      color: #111;
      outline: none;
      font-weight: 300;  
}
.inputboitePaiement input{
    all: unset;
      width: 100%;
      height: 35px; 
      border: 1px solid #268d36;
      border-radius: 15px;
      padding: 5px; 
      color: #111;
      outline: none;
      font-weight: 300;
  }
  