.title-bultin{
    text-align: center;
    width: 100%;
    margin: 0;

}
.tableNote tr{
   text-align: center; 
}
.tableNote tr th{
    font-size: 25px;
}

.header-h3{
    margin-top: 50px;

}
hr{
    background-color: #000;
    padding: 0;
    margin: 0 0 5px 0;
    height: 1px;  
}

.entete-tableau{
    background-color: rgb(242, 240, 240);
}


.inputBulletin{
    width: 25px;
    height: 25px;
}
.footer-bulletin{
    font-size: 20px;
    font-weight: 600;
    font-family: sans-serif;

}

@media print {
	@page {
		margin-top: 0;
		margin-bottom: 0;
        size: A4;
        margin-top: 2mm;
        margin-left: 10mm;
        margin-right: 10mm;
	}
	body {
		padding-top: 5px;
		padding-bottom: 50px ;
	}
}
.print {
	@page :footer {
		display: none
	}

	@page :header {
		display: none
	}
} 

#ligneTab td{
    padding: 3px 3px 3px 10px;
}

#tabApreciation th, #tabApreciation td{
    padding: 3px 3px 3px 10px;
}