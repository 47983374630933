.eleveconteiner {
    width: 90%;
    margin: 0 0 10px;
    
}
/* .fieldset-reinscrire{
    padding: 50px 30px 20px 70px  ;
} */
.fieldset-reinscrire{
    padding: 20px 40px 30px 20px  ;
}

.eleveform .inputboite {
    position: relative;
    width: 100%;
    margin-bottom: 20px;
}

.eleveform h3 {
    color: #268d36;
    text-align: center;
    font-weight: 700;
    margin-bottom: 30px;
    margin-top: -10px;
}

.eleveform p a {
    text-decoration: none;
    color: #111;
}

.eleveform .inputboite input {
    width: 100%;
    border: 1px solid #268d36;
    border-radius: 5px;
    padding: 4px;
    color: #111;
    outline: none;
    font-weight: 300;
}

.eleveform .matricule input {
    width: 100%;
    border: 5px solid #268d36;
    border-radius: 5px !important;
    /* padding: 4px; */
    color: #111;
    outline: none;
    font-weight: 300;
}

.eleveform .form-group select {
    width: 100%;
    border: 1px solid #268d36;
    border-radius: 5px;
    padding: 5px;
    color: #111;
    outline: none;
    font-weight: 300;
}

.table-bordered {
    width: 100%;
}

.text {
    /* margin: 10px 0 13px 37px; */
    padding: 0px 0px   !important;
    font-size: 17px;
    font-family: Ubuntu;
    font-size: 18.5px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.33px;
    text-align: center;
    color: #000;
}


.bar{
    background-color: #000;
    border: 1px solid #000;
    width: 100% ;
    /* width: 90%; */
}

.form-check {
    color: #020202;
    font-size: 17px;
}

.form-check-input input[type="radio"] {
    color: #33de04;
}

.formulaire fieldset {
    border: 4px solid #268d36;
    border-radius: 20px;
    position: relative;
    padding: 0 important;
}

.title-form {
    color: #268d36;
    position: relative;
    top: -20px;
    right: -90px;
    width: 200px;
    padding: 0 1px;
    background-color: rgb(255, 255, 255);
    font-weight: 600;
    margin-left: 100px;
}

.conteneur-btn {
    display: flex;
    justify-content: center;
    position: relative;
    top: 30px;
}

.btn1-connexion {
    font-size: 1.2em;
    text-transform: capitalize;
    font-weight: 700;
    color: #fff;
    display: inline-block;
    cursor: pointer;
    letter-spacing: 1px;
    outline: none;
    border: 1px solid  #268d36 !important;
    border-radius: 5px;
    transition: 0.5s;
    padding: 10px 10px;
    margin-right: 60px;
    margin-left: 60px;
    width: 200px;
}

.btn1-connexion.recherche {
    /* font-size: 1.2em; */
    /* text-transform: capitalize; */
    /* font-weight: 700; */
    color: #fff;
    /* display: inline-block; */
    /* cursor: pointer; */
    /* letter-spacing: 1px; */
    /* outline: none; */
    /* border: none; */
    /* border-radius: 5px; */
    /* transition: 0.5s; */
    padding: 4px 0px;
    /* margin-right: 40px; */
    margin-left: 20px;
    width: 90%;
    font-size: 18px;
    background: #268d36;
    margin-top: 8px;
} 
.btn1-connexion.enregistrer {
    background: #268d36;
}

.btn1-connexion.annuler {
    background: #e5ca3a;
}

.Rectangle-691 {
    display: flex;
    justify-content: center;
}

.inscription1 {
    font-size: 1.2em;
    text-transform: capitalize;
    font-weight: 700;
    color: #fff;
    display: inline-block;
    cursor: pointer;
    letter-spacing: 1px;
    outline: none;
    /* border: none; */
    /* border-radius: 10px; */
    transition: 0.5s;
    padding: 10px 10px;
    width: 350px;
    background-color: #fff;
    position: relative;
    left: 100px;
    top: 25px;
    z-index: 12;
    border: 1px solid #268d36;
   
}
.reinscription1.hover{
    border: none;
}
.reinscription1 {
    font-size: 1.2em;
    text-transform: capitalize;
    font-weight: 700;
    color: #fff;
    display: inline-block;
    cursor: pointer;
    letter-spacing: 1px;
    outline: none;
    border: none;
    border-radius: 10px;
    background-color: #268d36;
    transition: 0.5s;
    padding: 10px 10px;
    margin-right: 60px;
    margin-left: 60px;
    width: 350px;
    /* background-color: #fff; */
    position: relative;
    right: 10px;
    top: 25px;
    z-index: 20;
}

.text {
    display: inline-block;
    padding-top: 30px;
}

@media only screen and (max-width :768px) {
    .inputboite input {
        width: 90%;
        margin-bottom: 10px;
    }
    .inputboite select {
        width: 90%;
    }
    .icon-chevron {
        right: 50px;
        cursor: auto;
    }
    .upload {
        width: 100px;
        position: relative;
    }
    .title-upload {
        text-align: center;
        margin-bottom: 70px;
        margin-top: -5px;
    }
}

@media only screen and (max-width :505px) {
    fieldset {
        width: 400px;
    }
    .title-form {
        right: -10px;
        left: -40px;
    }
}

@media only screen and (max-width :436px) {
    fieldset {
        border: 1px solid #268d36;
        border-radius: 20px;
        position: relative;
        width: 350px;
    }
    .title-form {
        left: -60px;
        font-size: 22px;
        padding: 0 5px;
        width: 220px;
    }
    .btn1-connexion {
        padding: 10px 20px;
        margin-right: 30px;
        margin-left: 30px;
        width: 200px;
    }
}

.recherge {
    width: 40%;
    height: 40px;
    margin: 3.5px 10px 3.5px 5.5px;
    padding: 4px 15px 20px 20px;
    margin-top: -3px;
    border-radius: 5px;
    border: solid 0.5px #268d36;
    background-color: #268d36;
}

.reinscription link {
    color: #268d36;
}