.Total-dinscriptionMensualits {
    width: 92.5px;
    height: 11.5px;
    margin: 28px 17px 36.5px 11.5px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.33px;
    text-align: center;
    color: #000;
    text-decoration: none;
}

.Total-depence {
    width: 92.5px;
    height: 11.5px;
    margin: 28px 17px 36.5px 11.5px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.33px;
    text-align: center;
    color: #000;
}

.Nos-avoir-rel {
    width: 92.5px;
    height: 11.5px;
    margin: 28px 17px 36.5px 11.5px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.33px;
    text-align: center;
    color: #000;
    margin-top: 5px;
}

.Rectangle-206 {
    width: 100% !important;
    height: 80px;
    /* margin: 25px 15px 7px 12px; */
    /* padding: 20.5px 0.5px 5.5px 1px; */
    border-radius: 10px;
    border: solid 1.5px #268d36;
    background-color: #fff;
}

.table-bordered {
    width: 100%;
}

.Rectangle-209 {
    width: 100% !important;
    height: 80px;
    /* margin: 25px 15px 7px 12px; */
    /* padding: 20.5px 0.5px 5.5px 1px; */
    border-radius: 10px;
    border: solid 1.5px #f82020;
    background-color: #fff;
}

.Rectangle-210 {
    width: 100% !important;
    height: 80px;
    /* margin: 25px 15px 7px 12px; */
    /* padding: 20.5px 2.5px 5.5px 1px; */
    border-radius: 10px;
    border: solid 1.5px #edfb4e;
    background-color: #fff;
}

.AFFICHAGE-BUDGETS {
    /* //  width: 422.5px; */
    background-color: #fff;
}

.Rectangle-221 {
    width: 100% !important;
    height: 0.5px;
    margin: 0 0 3.5px;
    background-color: #268d36;
}

.Rectangle-222 {
    width: 100% !important;
    height: 0.5px;
    margin: 0 0 3.5px;
    background-color: #f82020;
}

.Rectangle-223 {
    width: 100% !important;
    height: 0.5px;
    margin: 0 0 3.5px;
    background-color: rgba(237, 251, 78, 0.69);
}

.mansualiteFcfa {
    width: 89px;
    height: 14px;
    margin: 3.5px 1.5px 0 2px;
    font-family: Ubuntu;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.6px;
    text-align: center;
    color: #268d36;
}

.depenceFcfa {
    width: 89px;
    height: 14px;
    margin: 3.5px 1.5px 0 2px;
    font-family: Ubuntu;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.6px;
    text-align: center;
    color: #f82020;
}

.reelFcfa {
    width: 89px;
    height: 14px;
    margin: 3.5px 1.5px 0 2px;
    font-family: Ubuntu;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.6px;
    text-align: center;
    color: #f2b108;
}


/* LES BUTTON */

.Rectangle-195 {
    width: 45.5px;
    height: 13px;
    margin: 0 0 13px 67px;
    padding: 3px 10px 3.5px 7.5px;
    border-radius: 7.5px;
    background-color: #268d36;
}

.nouvelle-ajout {
    width: 52px;
    height: 30.5px;
    flex-grow: 0;
    margin: 1px 0 1px 1.4px;
    font-family: Ubuntu;
    font-size: 14.5px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.28px;
    text-align: left;
}

.nouvelle-ajout {
    width: 52px;
    height: 19.5px;
    flex-grow: 0;
    /* margin: 1px 0 1px 1.4px; */
    font-family: Ubuntu;
    font-size: 19px;
    font-weight: bold;
    text-align: center;
    color: #ffffff;
    /* hover: #ffffff; */
}

.nouvelle-ajout:hover {
    color: #ffffff;
}

/* .Rectangle-180 {
  
    height: 35px;
    flex-grow: 0;
    position: left;
    float: right;
    margin: 0.5px 8px 12.5px 0.5px;
    padding: 5px 5px 8px 3.5px;
    border-radius: 8.5px;
    background-color: #268d36;
} */
.Rectangle-180 {
font-size: 0.85em;
text-transform: capitalize;
font-weight: 700;
color: #fff;
background: #268d36;
display: inline-block;
cursor: pointer;
letter-spacing: 1px;
outline: none;
border: none;
border-radius: 15px;
transition: 0.4s;
padding: 8px 15px;
text-align: center;
font-family: sans-serif;
}
.linkLien{
    text-decoration: none;
}

.Ellipse-46 {
    width: 25px;
    height: 20.5px;
    flex-grow: 0;
    margin: 0 1.4px 0 0;
    padding: 0 2.5px 0.5px 2px;
    border-radius: 50%;
    background-color: #c4c4c4;
}

.entre {
    margin: 5.5px 4px 0 13.5px;
    padding: 0.5px 5px 1px 3.5px;
    border-radius: 7.5px;
    background-color: rgba(56, 194, 120, 0.75);
}

.sortie {
    margin: 5.5px 4px 0 13.5px;
    padding: 0.5px 5px 1px 3.5px;
    border-radius: 7.5px;
    background-color: rgba(226, 120, 120, 0.64);
}

.date {
    width: 3110px;
}