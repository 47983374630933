.box {
    width: 17px;
    color: #3a42e5;
    background-color: #268d36;
}

.box1 {
    -webkit-appearance: none;
    appearance: none;
    height: 17px;
    width: 17px;
    /* margin: calc(0.75em - 11px) 0.25rem 0 0; */
    /* margin-left: 18px; */
    /* vertical-align: top; */
    /* border: 2px solid #ddd; */
    border-radius: 5px;
    background: #787b78 no-repeat center center;
}


/* input:checked {
    border-radius: 3px;
    outline: 2px solid #268d36;
    background-color: #268d36;
} */

.dive {
    width: 3px;
}

.box {
    -webkit-appearance: none;
    appearance: none;
    height: 17px;
    text-align: center;
    /* margin: calc(0.75em - 11px) 0.25rem 0 0; */
    /* margin-top: 18px; */
    /* vertical-align: top; */
    /* border: 2px solid #ddd; */
    border-radius: 5px;
    background: #268d36 no-repeat center center;
}

.action {
    margin-left: -9px;
}

.prenom {
    width: 100px;
}
.cadreEffectiv{
    margin: 20px 10px 2px 10px;
    padding: 10px 0px 8px 0px;
}
.row.effectif{
  
    margin-top: -28px;
}
.fieldset{
    margin-top: -20px;
}