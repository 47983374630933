.left {
    width: 100%; 
    height: 100vh;
    background-image: linear-gradient(rgba(79, 189, 130, 0.53), rgba(68, 191, 125, 0.53)), url('../../img/background1.jpeg') ;
    background-position:center;
    background-size:cover;
    background-repeat: no-repeat;
    display: flex;
    padding-top: 70px;
    justify-content: center;
    /* align-items: center;   */
}
.icon-polygon{
    color: #268d36;
    font-size:60px;
    cursor: pointer;
    line-height: 20px;
}
.left h2{
    margin-top: 10px;
    text-align: center;
    font-family: sans-serif;
    color: #000;
    font-weight: 600;
}
.right{
    padding-top: 70px;
    text-align: justify;
    padding-left: 200px;
    
}
.right h2{
    /* text-align: center; */
    padding-left: 0px;
    padding-bottom: 20px;
    font-family: sans-serif;
    color: #000;
    font-weight: 600;

}
.right p{ 
     /* padding-left: 200px; */
     font-size: 16px;
}
.right p span{
    font-size: 18px;

}
@media only screen and (max-width: 998px) {
    .right{
        padding-top: 70px;
        text-align: justify;
        padding-left: 100px;
        
    }
    
}
