.col-md-3{
    padding: 20.5px 0.5px 5.5px 1px;
}

.bloc-classe{
    cursor: pointer;
}
.CreationMatiere{

}

/* .nouvelle-ajout {
    width: 52px;
    height: 30.5px;
    flex-grow: 0;
    margin: 1px 0 1px 1.4px;
    font-family: Ubuntu;
    font-size: 14.5px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.28px;
    text-align: left;
} */

.nouvelle-ajoutMatiere {
     /* width: 10px; 
    height: 19.5px;
    flex-grow: 0; */
    /* margin: 1px 0 1px 1.4px; */
    font-family: Ubuntu;
    /* font-size: 19px;
    font-weight: bold; */
    text-align: center;
    color: #ffffff;
    /* hover: #ffffff; */
}
.nouvelle-ajoutMatiere{

flex-grow: 0;
/* margin: 1px 0 1px 1.4px; */
font-family: Ubuntu;
font-size: 14.5px;
font-weight: bold;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: 0.28px;
text-align: center;
}
.nouvelle-ajoutMatiere:hover {
    color: #ffffff;
}

.CreationMatiere {
    width: 130px;
    height: 35px;
   
    /* position: left; */
    /* float: right; */
    text-align: center;
    margin: 0.5px 8px 12.5px 0.5px;
    padding: 5px 5px 8px 3.5px;
    border-radius: 8.5px;
    background-color: #268d36;
}