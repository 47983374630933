.formulaire fieldset{
    border: 1px solid #268d36 ;
    border-radius: 20px;
    position: relative;
    
}
.title-form{
    color: #268d36;
    position: relative;
    top:-27px;
    right: -60px;
    width: 280px;
    padding: 0 10px;
   background-color: #fff;
   font-weight: 600;
   margin-left: 100px;
}
.inputboite input{
  all: unset;
    width: 100%; 
    border: 1px solid #268d36;
    border-radius: 15px;
    padding: 10px; 
    color: #111;
    outline: none;
    font-weight: 300;  
    margin:   10px !important;
}
.inputboite select{
  all: unset;
  border: 1px solid #268d36;
   width: 100%; 
   outline: none;
   padding: 10px; 
   color: #111;
   border-radius: 15px;
   position: relative;
   margin:   10px !important;

}
.icon-chevron{
  position: absolute;
  font-size: 20px;
  right: 0;
  top: 10px;
  color: #268d36;
  cursor: pointer;

}
.conteneur-btn{
    display: flex;
    justify-content: center;
    position: relative;
    top: 30px;
}

.btn1-connexion{
    font-size: 1.2em;
    text-transform:capitalize;
    font-weight:700 ;
    color: #fff;
    display: inline-block;
    cursor: pointer;
    letter-spacing: 1px;
    outline: none;
    border: none;
    border-radius: 5px;
   transition: 0.5s;
   padding:10px 10px ;
   margin-right: 60px;
   margin-left: 60px;
   width: 200px;  
}
input{
  margin: 0 20px;
}
.btn1-connexion.enregistrer{
    background: #268d36;

}
.btn1-connexion.annuler{
    background: #e5ca3a;

}
/* ********** IMAGE UPLOAD ****** */
.upload{
    width: 100px;
    position: relative;
    margin-bottom: 30px;
    margin: auto;
   
  }
  
  .upload img{
    border-radius: 50%;
    border: 2px solid #d9d9d9;
    background-color: #d9d9d9;
  }
  .title-upload{
    text-align: center;
  }
  
  .upload .round{
    cursor: pointer;
    position: absolute;
    bottom: 10px;
    right: 5px;
    background: #fff;
    width: 37px;
    height: 37px;
    line-height: 33px;
    text-align: center;
    border-radius: 50%;
    overflow: hidden;
    margin: 40px 0px 0px 0px !important;
  }
  .form-controluser{
    height: 20px !important;
    border: 1.5px solid  #268d36 !important;
  }
 
  .upload .round input[type = "file"]{
    position: absolute;
    transform: scale(2);
    opacity: 0;
    float: right !important;
    cursor: pointer;
    margin: 10px 10.5px 10px 10px !important;
  }
  .upload-file{
    margin-left :30px !important;
    margin-top :20px !important;
  
  }

  @media only screen and (max-width :768px) {
      .inputboite input{
          width:90%; 
          margin-bottom: 10px;
      }
      .inputboite select{
        width: 90%; 
    }
    .icon-chevron{
      right: 50px;
      cursor: auto;
    }
    .upload{
      width: 100px;
      position: relative;
    }
    .title-upload{
      text-align: center;
      margin-bottom: 70px;
      margin-top: -5px;
    }
  }

  @media only screen and (max-width :505px) {
    
    fieldset{
      width: 400px;
    }
    .title-form{
      right: -10px;
      left: -40px;
  }
 
  }
  @media only screen and (max-width :436px) {
    fieldset{
      border: 1px solid #268d36 ;
      border-radius: 20px;
      position: relative;
      width: 350px;

    }
    .title-form{
      left: -60px;
     font-size: 22px;
     padding: 0 5px;
     width: 250px;
  }
  .btn1-connexion{
   padding:10px 20px ;
   margin-right: 30px;
   margin-left: 30px;
   width: 200px;  
}

  }
  
