.col-md-3 {
    padding: 20.5px 0.5px 5.5px 1px;
}

.bloc-classe {
    cursor: pointer;
}

.baniere {
    height: 350px;
    width: 100%;
    background-image: linear-gradient(rgba(56, 194, 120, 0.53), rgba(56, 194, 120, 0.53)), url(/src/img/accueil.jpg);
    /* background-attachment: fixed; */
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.title {
    text-align: center;
    color: #fff;
}

.adresse {
    color: #fff;
    font-size: 16px;
}

.adresse span {
    font-weight: 700;
}

.btn-plus {
    all: unset;
    background-color: #268d36;
    padding: 10px 20px;
    color: #fff;
    font-size: 16px;
    box-shadow: 5px 10px 15px rgba(0, 0, 0, .8);
    border-radius: 5px;
}

.btn-plus:hover {
    background-color: #4db95e;
}


/* ####### CardList ######## */

.card {
    margin: 15px;
    color: #fff;
    box-shadow: 0 2px 4px rgb(0 0 0 / 10%), 0 8px 16px rgb(0 0 0 / 10%);
    width: 300px;
    position: relative;
    padding-bottom: 20px;
}

.card-title {
    padding: 10px 0 10px 15px;
    font-size: 20px;
}

.dropdown-toggle.accueil-detail {
    position: absolute;
    right: 10px;
    top: -50px;
    color: #268d36;
    cursor: pointer;
}

.dropdown-toggle.accueil-detail::after {
    display: none;
}


/* ####### Descripteur ######## */

.contenu-descrpteur {
    padding: 0 0 0 15px;
    display: flex;
}

.descripteur-form {
    width: 50px;
    height: 50px;
    color: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.text-title {
    display: flex;
    flex-direction: column;
    margin-left: 7px;
}

.descripteur-text {
    margin: 0;
}

.descripteur-detail {
    color: rgb(129, 125, 125);
    font-size: 13px;
}

@media only screen and (max-width :1282px) {
    .card {
        width: 100%;
    }
}

@media only screen and (max-width :1096px) {
    .card-title {
        padding: 10px 0 10px 10px;
        font-size: 18px;
    }
    .dropdown-toggle.accueil-detail {
        position: absolute;
        right: 5px;
        top: -50px;
        color: #268d36;
        cursor: pointer;
    }
}

@media only screen and (max-width :562px) {
    .card {
        width: auto;
    }
}

@media only screen and (max-width :541px) {
    .container {
        width: 90%
    }
}