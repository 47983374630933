.baniereAccueilParent {
    height: 450px;
    width: 80%; 
    background-image: linear-gradient(rgba(56, 194, 120, 0.53), rgba(56, 194, 120, 0.53)), url(/src/img/accueil1.jpg);
    /* background-attachment: fixed; */
    background-position:center;
    background-size:cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; 
}
.containerParent{
    border: 1px solid rgba(56, 194, 120, 0.53);
}
.title{
    text-align: center;
    color: #fff;
   
}
.adresse{
    color: #fff;
    font-size: 16px;
}
.adresse span{
 
    font-weight: 700;
}
.btn-plus{
    all: unset;
    background-color: #268d36;
    padding: 10px 20px;
    color: #fff;
    font-size: 16px;
    box-shadow:5px 10px 15px rgba(0, 0 , 0 , .8) ;
    border-radius: 5px;
}
.btn-plus:hover{
    background-color: #4db95e;
}

/* ####### CardList ######## */

.cardAccueil{
    margin-top: 25px ;
    color: #fff;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, 
                rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    width: 300px;
    width: 100%;
    position: relative;
    padding-bottom: 20px; 
}
.cardAccueil11{
    margin-top: 0px ;
    padding-bottom: 20px;

}

.cardAccueil1{
    margin-top: 15px ;
    padding-bottom: 20px;

}
.card-title{
    padding: 10px 0 10px 15px;
    font-size: 20px;
}
.dropdown-toggle.accueil-detail{
    position: absolute;
    right: 10px;
    top: -50px;
    color: #268d36;
    cursor: pointer;

}
.dropdown-toggle.accueil-detail::after  {
    display: none;
    }
/* ####### Descripteur ######## */
.contenu-descrpteur{
padding: 0 0 0  15px;
display: flex;
}
.descripteur-form{
    width: 50px;
    height: 50px;
    color: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
       
}
.text-title{
    display: flex;
    flex-direction: column;
    margin-left: 7px;

}
.descripteur-text{
   margin: 0;   
}
.descripteur-detail{
    color: rgb(129, 125, 125);
    font-size: 13px;
}
.tilte-fieldset-parant {
    color: #268d36;
    position: absolute;
    top: -20px;
    background-color: #fff;
    /* width:fit-content; */
    /* padding: 0px; */
    left: 50px;
    z-index: 2;
  }
  .content-contact{
    height:80px;
    background-color: #268d36;
    margin-left: 0;
    display: flex;
    justify-content: center;
    align-items: center;

   
  }
  .contact-title{
    color:#fff;
    text-align: center;
    font-size:25px;
    font-weight :600;
  }
 @media only screen and (max-width :1282px) {
    .card{
        width: 100%;
    }
}
@media only screen and (max-width :1096px) {
    .card-title{
        padding: 10px 0 10px 10px;
        font-size: 18px;
    }
    .dropdown-toggle.accueil-detail{
        position: absolute;
        right: 5px;
        top: -50px;
        color: #268d36;
        cursor: pointer;
    
    }
}
@media only screen and (max-width :562px) {
    .card{
        width: auto;
    }
}
@media only screen and (max-width :541px) {
   .container{
    width: 90%
   }
}
