.entete-detail{
    background-color: #268d36;
}
.title_entete-detail{
    text-align: center;
    color: #fff;
    padding: 10px 0;
}
.paiement-trimestre{
    margin-top: 30px;
}
.ligne-detail{
    height: 10px;
    
    border-radius: 10px;
}
.ligne-detail.detail1{
    background-color: hsl(42, 93%, 63%);
}
.ligne-detail.detail2{
    background-color: #4d4af8;
}
.ligne-detail.detail3{
    background-color: #f84a4a;
}
.ligne-detail.detail4{
    background-color: #268d36;
}
 
.text-detail{
    margin-top: -12px;
}
.card-libelle{
    border-radius: 7px;
    text-align: center;
}
.card-libelle.trimeste{
    border: 1px solid #edfb4e;
    box-shadow: #edfb4e 0px 1px 3px 0px, 
                #edfb4e 0px 0px 0px 1px;

}
.card-libelle.aport{
    border: 1px solid #4d4af8;
    box-shadow: #4d4af8 0px 1px 3px 0px, 
                #4d4af8 0px 0px 0px 1px;

}
.card-libelle.insc{
    border: 1px solid #f84a4a;
    box-shadow: #f84a4a 0px 1px 3px 0px, 
                #f84a4a 0px 0px 0px 1px;

}

.card-libelle.trimeste h3{
    border-bottom: 1px solid #edfb4e;
}
.card-libelle.aport h3{
    border-bottom: 1px solid #4d4af8;
}
.card-libelle.insc h3{
    border-bottom: 1px solid #f84a4a;
}

.card-libelle h2{
    color:#f2b108 ;
}
.footer-detail{
    background-color: #268d36;
    display: flex;
    justify-content: space-between;
  
  
}
.footer-detail h4{
    color: #fff;  
}
.groupeBtn{
    margin: 30px 0;
    width: 100%;
    display: flex;
    /* flex-direction: column; */
    justify-content:center;
    align-items: center;
}
.btn-detail{
    all: unset;
    width: 400px;
    padding: 10px;
    margin: 0 30px;
    color: #fff;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    font-family: sans-serif;
    border-radius: 7px;  
    transition: ease-in-out .5s;
}
.btn-imprimer{
    background-color: #268d36;
}
/* .btn-reset{
    background-color: #e5ca3a;
} */
.btn-imprimer:hover{
    background-color: #217b2e;
}
/* .btn-reset:hover{
    background-color: #c2ac31;
} */

@media only screen and (max-width: 768px){
    .text-detail{
        margin-top: 10px;
    }  
}
@media only screen and (max-width: 719px){
    .footer-detail{
        background-color: #268d36;
        display: flex;
        align-items: center;
        flex-direction: column;
    }
   
    
}