.headerEmplois{
    border-color: black;
    border: 1px solid #268d36 ;
    color: #0c0d0c;
    font-weight: bold;
    font-size: 20px;
    text-align: center; 
}     
.contentEmplois{
    font-weight: 200;
    font-size: 14px;
}

.titleEmplois{
    border-bottom: 1px solid #268d36;
    margin-bottom: 0;
}
