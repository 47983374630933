.onof{
    border-radius: 20rem;
    border-radius: 20rem;
}
.toggle.ios, .toggle-on.ios, .toggle-off.ios { border-radius: 20rem; }
  .toggle.ios .toggle-handle { border-radius: 20rem; }

body{
    /* font-family: "Roboto Slab", serif; */
}
  