.mail textarea{
    border: 2px solid#268d36; 
    border-radius: 10px;
}
.radioMail input{
    box-sizing: border-box;
    padding: 0;
   color: #268d36;
   background-color:  #268d36;
}

  
