.title-bultin{
    text-align: center;
    width: 100%;
    margin: 0;

}
.entete-classe{
    margin-top: 50px;
    display: flex;
    justify-content: space-around;
    margin-bottom: 10px;
    position: relative;
}

.entete-classe .classe{
    display: inline-block;
    position: absolute;
    top: -5px;
    /* right: 180px; */
    /* right: 180px; */
    left: 455px;
    opacity: 1; 
   
}
.entete-classe .semestre{
    display: inline-block;
    position: absolute;
    /* top: px; */
    /* right: 180px; */
    /* right: 180px; */
    /* left: 455px; */
    opacity: 1;

}
.entete-classe h3{
    font-size: 30px;

}
.entete-classe .info-classe{
    font-size: 27px;
    opacity: .7;
    margin-left: 15px;
    


}


.herder-bulletin{
    display: flex;
    justify-content: space-between;
}
.le-centre{
    margin-top: 40px;
}
.observation{
    border: 1px solid rgb(242, 240, 240);
}
.entete-observation{
    background-color: rgb(242, 240, 240);
    /* border-left: 2px solid rgb(242, 240, 240); */

}
.entete-observation p{
    text-align: center;
    font-size: 25px;
    margin-bottom: 0;

    
}
.entete-observation .title1{
    padding-top: 5px;

}
.entete-observation .title2{
    border-bottom: 1px solid rgba(72, 94, 144, 0.16);;
    padding-bottom: 11px;

}
.corp-observation {
    padding-top: 70px;
}

.corp-observation h4{
    text-align: center;

}

.entete-tableau p{
    margin: 0;

}
.entete-tableau h4{
    margin: 0;

}
.entete-tableau .entete-matiereA, 
.entete-tableau .entete-matiereF{
    padding-bottom: 30px;
   

}
.footer-bulletin{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-right: 90px;
    position: relative;
}
.footer-bulletin .moyenne{
    display: inline-block;
    position: absolute;
    top: 3px;
    right: 51px;
    opacity: .7;
    /* padding-top: -50px; */
}
.footer-bulletin .rang{
    display: inline-block;
    position: absolute;
    top: 30px;
    right: 60px;
    opacity: .7;
}
.footer-bulletin .rang-annuel{
    display: inline-block;
    position: absolute;
    /* top: 50px; */
    right: 60px;
    opacity: .7;

}

.signature{
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    /* margin-top: 2px; */
    position: relative;
 
}
.signature .rang-annuel{
    display: inline-block;
    position: absolute;
    top: -5px;
    /* right: 180px; */
    right: 180px;
    /* left: 20px; */
    opacity: .7;

}

.borederTitle {
    border-bottom: thick double #242222;
    border-top: thick double #242222;
    text-align: center;
    margin-top: 2px;
    margin-bottom: 3px;
}

@media print {
	@page {
		margin-top: 0;
		margin-bottom: 0;
        size: auto;
        margin-top: 5mm;
        margin-left: 10mm;
        margin-right: 10mm;
	}
	body {
		padding-top: 10px;
		padding-bottom: 50px ;
		
	}
}
.print {
	@page :footer {
		display: none
	}

	@page :header {
		display: none
	}
} 


#tabPrimaire td{
    padding: 3px 3px 3px 10px;
}