

.checkboxAnnee input[type="checkbox"] {
    display: inline-block;
    position: relative;
    top: -1px;
    width: 30px;
    height: 30px;
    margin: 4px 0px 0 0;
    vertical-align: middle;
    background-color: rgb(51, 5, 5) left top no-repeat;
    border-radius: 100% !important;
    cursor: pointer;
    /* color: aquamarine; */
  }