.inputSelect select{
    all: unset;
    border: 1px solid #268d36;
     width: 100%; 
     outline: none;
     padding: 10px 10px 10px 10px; 
     margin: 10PX 10px 10px 30px;
     color: #111;
     border-radius: 5px;
     position: relative;
  
  }
  .icon-chevrone{
    position: absolute;
    font-size: 20px;
    right: -26px;
    top: 25px;
    color: #268d36;
    cursor: pointer;
  
  }

  @media only screen and (max-width :769px) {
    .inputSelect select{
        all: unset;
        border: 1px solid #268d36;
         width: 400px; 
         outline: none;
         padding: 10px 10px 10px 10px; 
         margin: 10PX 10px 10px 10px;
         color: #111;
         border-radius: 5px;
         position: relative;
      
      }
      .icon-chevrone{
        right: 70px;
      cursor: auto;
      padding: 0px 0px 0px 0px; 
      margin: 0PX 0px 0px 0px;
      }
  }

  @media only screen and (max-width :543px) {
    .inputSelect select{
        all: unset;
        border: 1px solid #268d36;
         width: 300px; 
         outline: none;
         padding: 10px 10px 10px 10px; 
         margin: 10PX 10px 10px 10px;
         color: #111;
         border-radius: 5px;
         position: relative;
      
      }
      .icon-chevrone{
        right: 70px;
      cursor: auto;
      padding: 0px 0px 0px 0px; 
      margin: 0PX 0px 0px 0px;
      }
  }