.content-tab {
    margin-top: 50px;
}

.fieldset { 
    border: 1px solid #c0bbbb;
    border-radius: 2px;
    position: relative;
    padding: 40px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}


.linkUser a{
    margin-left: 20px;
    text-decoration: none;
}

.tilte-fieldset.user {
    color: #268d36;
    position: absolute;
    top: -20px;
    background-color: #fff;
    padding: 0 2px;
    left: 50px;
}

.action-icon {
    display: inline-block;
    margin-left: 10px;
    font-size: 16px;
    color: #000
}

@media only screen and (max-width: 620px) {
    .action-icon {
        display: block;
        margin: auto;
        font-size: 18px;
        margin-top: 5px;
    }
}