/* .contact{
    background-image:url(./img/img-form.jpg) ;
   background-size: cover;
   box-shadow: 2px 2px 12px rgba(0, 0 , 0 , .8);
   width: 100%;
   background-position: unset;
} */

.content-form {
    height: 100vh;
    width: 100%;
    background-image: linear-gradient(rgba(56, 194, 120, 0.53), rgba(56, 194, 120, 0.53)), url(../../img/background.jpeg);
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact .titre-text span {
    color: #268d36;
    font-size: 2em;
    line-height: 20px;
}

.contactform {
    padding: 75px 50px;
    border-radius: 10px;
    background: #fff;
    box-shadow: 5px 15px 50px rgba(0, 0, 0, .8);
    width: 500px;
    max-width: 600px; 
}

 .contactform .inputboite {
    position: relative;
    width: 100%;
    margin-bottom: 40px;
} 
.contactform h1 {
    color: #268d36;
    text-align: center;
    font-size: 25px;
    font-weight: 500;
    margin-top: -40px;
}
.inputboite.pwd{
   position: relative;
}
.inputPassword{
    position: absolute;

}
.buttunPassword{
    all: unset;
    position: absolute;
    right: 0;
    top: 20px;

}
.iconPassword{
    width: 22px;
    height: 22px;
    color: #268d36
}

.contactform p {
    margin: 14px 0 7.5px;
    /* font-family: Ubuntu; */
    font-size: 3px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.33px;
    text-align: center;
    color: #000;
    padding: 10px
}

.contactform p a {
    text-decoration: none;
    color: #111;
}  

 .contactform .inputboite input {
    width: 90%;
    border: 1px solid #268d36;
    border-radius: 5px;
    padding: 10px;
    color: #111;
    outline: none;
    font-weight: 300;
} 

.btn-connexion{
    font-size: 1.2em !important;
    text-transform: capitalize !;
    font-weight: 700 !;
    color: #fff !;
    background: #268d36 !;
    display: inline-block !;
    cursor: pointer ! important;
    letter-spacing: 1px !;
    outline: none !;
    border-radius: 5px !important;
    transition: 0.5s;
    padding: 10px 20px !important;
    width: 100% !important; 
    border: 5px !important;
    
 } 

.btn-connexion:hover {
    color: #fff;
    background: #369945;
}

