    /* .contact{
    background-image:url(./img/img-form.jpg) ;
   background-size: cover;
   box-shadow: 2px 2px 12px rgba(0, 0 , 0 , .8);
   width: 100%;
   background-position: unset;
} */
    
    .apporttconteiner {
        /* height: 10vh; */
        width: 100%;
        /* background-image: linear-gradient(rgba(56, 194, 120, 0.53), rgba(56, 194, 120, 0.53)), url(../../img/background.jpeg); */
        background-attachment: fixed;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .contact .titre-text span {
        color: #268d36;
        font-size: 2em;
    }
    
    .apportform .inputboite {
        position: relative;
        width: 100%;
        margin-bottom: 20px;
    }
    
    .apportform h3 {
        color: #268d36;
        text-align: center;
        font-weight: 700;
        margin-bottom: 30px;
        margin-top: -10px;
    }
    
    .contacapportformtform p {
        color: #111;
        font-size: 18px;
        text-align: center;
        font-weight: 600;
    }
    
    .apportform p a {
        text-decoration: none;
        color: #111;
    }
    
    .apportform .inputboite input {
        width: 95%;
        border: 1px solid #268d36;
        border-radius: 5px;
        padding: 10px;
        color: #111;
        outline: none;
        font-weight: 300;
    }
    
    .apportform .form-group select {
        width: 100%;
        border: 1px solid #268d36;
        border-radius: 5px;
        padding: 10px;
        color: #111;
        outline: none;
        font-weight: 300;
    }
    
    .apportform .form-group textarea {
        width: 100%;
        border: 1px solid #268d36;
        border-radius: 5px;
        padding: 10px;
        color: #111;
        outline: none;
        font-weight: 300;
    }
    
    .btn-connexion {
        font-size: 1.2em;
        text-transform: capitalize;
        font-weight: 700;
        color: #fff;
        background: #268d36;
        display: inline-block;
        cursor: pointer;
        letter-spacing: 1px;
        outline: none;
        border: none;
        border-radius: 5px;
        transition: 0.5s;
        padding: 15px 0;
        width: 30%;
        align-items: left;
        justify-content: left;
    }
    
    .btn-annuler {
        font-size: 1.2em;
        text-transform: capitalize;
        font-weight: 700;
        color: #fff;
        background: #e5ca3a;
        display: inline-block;
        cursor: pointer;
        letter-spacing: 1px;
        outline: none;
        border: none;
        border-radius: 5px;
        transition: 0.5s;
        padding: 15px 0;
        width: 30%;
        align-items: right;
        justify-content: right;
        margin-top: right;
        /* padding: 19px 12px 31px 11.5px; */
        border-radius: 5px;
    }
    
    .btn-connexion:hover {
        color: #fff;
        background: #369945;
    }
    
    .conteneur-btn {
        display: flex;
        justify-content: center;
        position: relative;
        top: 30px;
    }
    
    .btn1-connexion {
        font-size: 1.2em;
        text-transform: capitalize;
        font-weight: 700;
        color: #fff;
        display: inline-block;
        cursor: pointer;
        letter-spacing: 1px;
        outline: none;
        border: none;
        border-radius: 5px;
        transition: 0.5s;
        padding: 10px 10px;
        margin-right: 60px;
        margin-left: 60px;
        width: 200px;
    }
    
    .btn1-connexion.enregistrer {
        background: #268d36;
    }
    
    .btn1-connexion.annuler {
        background: #e5ca3a;
    }
    
    .formulaire fieldset {
        border: 1px solid #268d36;
        border-radius: 20px;
        position: relative;
    }
    
    .title-form {
        color: #268d36;
        position: relative;
        top: -27px;
        right: -60px;
        width: 250px;
        padding: 0 10px;
        background-color: #fff;
        font-weight: 600;
        margin-left: 100px;
    }
    /* datatable matiere */
    .datatablefooter{
        display:flex;
        flex-direction:row;
        justify-content: space-between;
        align-items: center
      }
    .datatableshowing{
        color:#6b6c72;
    }
    .pagination{
        display: flex;
        justify-content: space-between;
        list-style: none;
        cursor: pointer;
        
    }
    .pagination a{
        padding: 10px;
        border: 1px solid transparent;
        color: #505050;
        text-decoration: none;
     }
     .pagination a:hover{
        color: #3965ce!important;
        border: 1px solid #cacaca;
        background-color: #e3eaf3;
      }
    .paginationLink{
        font-weight: bold;
        /* text-decoration: none; */
    }
    .paginationLink:hover{
        background-color: red;

    }
    .paginateActive a{
        color: #fff;
        padding: 8px 12px;
        text-decoration: none;
        
        border: 1px solid #cacaca;
        background: linear-gradient(to bottom,#fff 0,#eaeaea 100%);  
        background: #0d6efd;  
    }
    .paginationDisabled a{
        text-decoration: none;
    
      color: #cacaca;
    
    }

    @media only screen and (max-width :768px) {
        .inputboite input {
            width: 90%;
            margin-bottom: 10px;
        }
        .inputboite select {
            width: 90%;
        }
        .icon-chevron {
            right: 50px;
            cursor: auto;
        }
        .upload {
            width: 100px;
            position: relative;
        }
        .title-upload {
            text-align: center;
            margin-bottom: 70px;
            margin-top: -5px;
        }
    }
    
    @media only screen and (max-width :505px) {
        fieldset {
            width: 400px;
        }
        .title-form {
            right: -10px;
            left: -40px;
        }
    }
    
    @media only screen and (max-width :436px) {
        fieldset {
            border: 1px solid #268d36;
            border-radius: 20px;
            position: relative;
            width: 350px;
        }
        .title-form {
            left: -60px;
            font-size: 22px;
            padding: 0 5px;
            width: 220px;
        }
        .btn1-connexion {
            padding: 10px 20px;
            margin-right: 30px;
            margin-left: 30px;
            width: 100%;
        }
    }