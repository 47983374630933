.enregistrer{
  text-align: center !important;
  font-size: 1.2em;
    text-transform: capitalize;
    font-weight: 700;
    color: #fff;
    display: inline-block;
    cursor: pointer;
    letter-spacing: 1px;
    outline: none;
    border: none;
    border-radius: 5px;
    transition: 0.5s;
    padding: 0px 10px;
    margin-right: 90px;
    margin-left: 60px;
    width: 200px;
    background-color: #268d36;
}