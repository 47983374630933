.content-tab {
    margin-top: 50px;
}
.fieldset{
    border: 1px solid #c0bbbb ;
    border-radius: 2px;
    position: relative;
    padding: 40px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}
.tilte-fieldset{
    color: #268d36;
    position: absolute;
    left: 0;
    top: -20px;
    background-color: #fff;
    padding: 0 10px;
   
   
}
.profilUser {
    height: 55px !important;
    width: 55px !important;
    background-color: #bbb;
    border-radius: 50%;
    margin-left: -8px !important;
    margin-top: -10px !important;
 
    /* display: inline-block; */
  }
  .profil-user{
    height: 89px;
    width: 89px;
    background-color: #bbb;
    border-radius: 50%;
    margin-left: 20px;
    margin-top: -15px;
  }
  .linkExtrai{
    text-decoration: none !important;
    background-color: transparent !important;
    text-align: center ! important;
    
  
  }
  .buton{
    text-align: center;
    color: rgb(127, 15, 15);
  }
  .avatarEleve{
    height: 95px !important;
    width: 95px !important;
    background-color: #bbb;
    border-radius: 50% ;
    margin-left: 18px !important;
    margin-top: 1px !important;
  }
  .divDonneEleve{
    margin-left: 18px !important;
    margin-top: -19px !important;
  }
.ddd{
  margin-left: 25px !important;
  font-size: 20px !important;
}
  